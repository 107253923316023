import React, { useState, useEffect } from 'react';
import SEO from '../../components/SEO';
import './complaints.css';
import { postGautra } from '../../actions/gautraAction';

import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import Alert from '@paljs/ui/Alert';

import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';

import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';

import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function index() {
  const [name, setName] = useState('');
  const [allNames, setAllNames] = useState([]);
  let [altName, setAltName] = useState('');
  // const [displayName, setDisplayName] = useState('');
  const [isLoading, showLoader] = useState(false);
  const [isEdit, setEdit] = useState(true);
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  // const handleChangeAllNames = (event) => {
  //   let allNamesValue = event.target.value.replace(/\s+/g, ' ').trim();
  //   if (allNamesValue.indexOf(',') > -1) {
  //     const displayName = allNamesValue.replace(/,/g, '/');
  //     const nameArray = allNamesValue.split(',');
  //     setAllNames(nameArray);
  //     setDisplayName(displayName);
  //   } else {
  //     setAllNames(allNamesValue);
  //     setDisplayName(allNamesValue);
  //   }
  // };
  const clearAllPills = () => {
    setAllNames([]);
    setName('');
  };

  const removePill = (e) => {
    setAllNames(allNames.filter((item, x) => e !== x));
  };
  const handleInputName = () => {
    if (name !== '') {
      allNames.length
        ? setAllNames(
            allNames.map((item, index) => {
              if (index === 0) {
                return name.trim();
              }
              return item;
            }),
          )
        : setAllNames([name.trim()]);
    }
  };
  const createNewPill = (e) => {
    e.preventDefault();
    if (altName !== '') {
      setAllNames([...allNames, altName.trim()]);
      setAltName('');
    }
  };
  const handleChangeAltName = (event) => {
    setAltName(event.target.value);
  };

  const submitHandle = async () => {
    showLoader(true);
    try {
      const data = {
        name: name.trim(),
        all_names: allNames,
        display_name: allNames.join('/'),
        subcastes: [],
      };
      const response = await postGautra(data);
      showLoader(false);
      {
        /*let data = response.data.map((currentValue: { SNO: any }, Index: number) => {
          currentValue.SNO = Index + 1;
          return currentValue;
        });*/
      }
      setName('');
      setAllNames([]);
      toast('Gautra added Successfully');
    } catch (error) {
      showLoader(false);
    }
  };

  const addGautraForm = (
    <>
      <SEO title="Add Gautra" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }} key={'modal-drop'}>
          <Card status="Info">
            <CardHeader>Add a Gautra</CardHeader>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                  <InputGroup fullWidth>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(evnt) => handleChangeName(evnt)}
                      placeholder="Enter Name"
                      onBlur={handleInputName}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <div className="b-container">
                  <form className="search-container">
                    <InputGroup fullWidth>
                      <input
                        className="b-TextInput--lg size-100"
                        placeholder={'Add new gautra name.'}
                        value={altName}
                        onChange={(event) => handleChangeAltName(event)}
                        disabled={allNames.length ? false : true}
                      />
                    </InputGroup>
                    <button onClick={createNewPill} className="b-Button--primary">
                      Add alternate name
                    </button>
                  </form>
                  <section>
                    {allNames.length > 0 ? (
                      <div role="button" className="pill pill-clear" onClick={clearAllPills}>
                        <div className="pill-content">Clear All</div>
                      </div>
                    ) : null}

                    {allNames.map((k, index) => {
                      return (
                        <div role="button" className="pill pill-query">
                          <div className="pill-content">
                            {k}
                            {k !== name ? (
                              <button className="pill-close" onClick={() => removePill(index)} tabIndex="0">
                                <b className="center-btn fa fa-times-circle">X</b>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </section>
                </div>
              </Row>
            </CardBody>
            <CardFooter>
              <Button status="Info" type="button" shape="SemiRound" fullWidth onClick={() => submitHandle()}>
                Add Gautra
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
  return <>{isLoading ? <LoadingSpinner message={`Adding ${name} Gautra`} /> : addGautraForm}</>;
}
